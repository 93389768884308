import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './utils/i18n'
import GlobalStyle from './styles/global'
import { Spinner } from '@lalamove/karang'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
	padding: 48px 0;
	margin: auto;
`

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path="/order/:orderId">
				<Suspense
					fallback={
						<SpinnerContainer>
							<Spinner size="large" />
						</SpinnerContainer>
					}
				>
					<App />
				</Suspense>
			</Route>
			<Route
				path="/"
				component={() => {
					window.location.href = 'https://www.lalamove.com/'
					return
				}}
			/>
		</Switch>
		<GlobalStyle />
	</Router>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
