import styled from 'styled-components'

import CollapseCard, { CollapseCardItemProps } from '../components/DeliveryCard'

interface Props {
	deliveries: CollapseCardItemProps[]
}
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const TrackingList = (props: Props) => {
	const { deliveries }: { deliveries: CollapseCardItemProps[] } = props
	return (
		<Wrapper>
			{deliveries.map((delivery: CollapseCardItemProps, i: number) => {
				return (
					<CollapseCard delivery={delivery} key={`delivery-${i}`} />
				)
			})}
		</Wrapper>
	)
}

export default TrackingList
