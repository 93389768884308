import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Card from './Card'

export interface OrderInfoDataProps {
	form?: string
	orderRef?: string
	orderStatus: string
	updatedAt: string
}
interface Props {
	orderInfo: OrderInfoDataProps
}

const StyledCard = styled(Card)`
	padding: 0 12px;
`
const InfoBlock = styled.div`
	display: flex;
	font-size: 14px;
	color: #888;
	padding: 16px 0;
	font-weight: 500;
	&:not(:last-child) {
		border-bottom: 1px solid #e0e0e0;
	}
	span {
		display: inline-flex;
		white-space: nowrap;
		align-items: center;
		color: #303030;
		margin-right: 4px;
	}
`
const OrderInfo = (props: Props) => {
	const { orderInfo } = props
	const { form, orderRef, orderStatus, updatedAt } = orderInfo
	const { t } = useTranslation()
	return (
		<StyledCard>
			<InfoBlock>
				<span>{t('orderInformation.from')}</span>
				{form}
			</InfoBlock>
			<InfoBlock>
				<span>{t('orderInformation.orderRef')}</span>
				{orderRef}
			</InfoBlock>
			<InfoBlock>
				<span>{t('orderInformation.orderStatus')}</span>
				{t(`orderStatus.${orderStatus}`)}
			</InfoBlock>
			<InfoBlock>
				<span>{t('orderInformation.lastUpdate')}</span>
				{updatedAt}
			</InfoBlock>
		</StyledCard>
	)
}

export default React.memo(OrderInfo)
