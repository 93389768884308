import * as R from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Card from './Card'
import LogInfo, { LogProps } from './LogInfo'

interface StatusBarContainerProps {
	statusBarStep: number
}

export type CollapseCardItemProps = {
	deliveryId: string
	status: string
	updatedAt: string
	logs: LogProps[]
} & StatusBarContainerProps

interface Props {
	delivery: CollapseCardItemProps
}

const StyledCard = styled(Card)`
	padding: 12px;
	width: 100%;
	box-shadow: 0 2px 2px 0 #ccc;
	position: relative;
`

const BtnCollapse = styled.div`
	background-image: url('${process.env.PUBLIC_URL}/assets/load-more.svg');
	width: 20px;
	height: 20px;
	background-position: center;
	padding: 4px;
	position: absolute;
	right: 8px;
	top: 6px;
	cursor: pointer;
	${(props: { active: boolean }) =>
		props.active &&
		css`
			transform: rotate(180deg);
		`}
`
const DeliveryId = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #888;
	font-weight: 500;
	margin-bottom: 4px;
`
const Status = styled.div`
	font-size: 18px;
	font-weight: 500;
	color: #303030;
`
const UpdatedTime = styled.div`
	font-size: 10px;
	margin-bottom: 8px;
	color: #aaa;
`

const StatusBar = styled.div`
	height: 2px;
	background-color: #e8e8e8;
	width: 100%;
`
const StatusBarContainer = styled.div<StatusBarContainerProps>`
	display: flex;
	gap: 2px;
	${StatusBar} {
		&:nth-child(-n + ${props => props.statusBarStep}) {
			background-color: #f16622;
		}
	}
` as React.FunctionComponent<StatusBarContainerProps>

const DeliveryCard = (props: Props) => {
	const { t } = useTranslation()
	const { delivery } = props
	const [deliveryId, status, updatedAt, statusBarStep, logs] = R.props(
		['deliveryId', 'status', 'updatedAt', 'statusBarStep', 'logs'],
		delivery
	) as [string, string, string, number, LogProps[]]
	const [isActive, setIsActive] = useState(false)
	const toggleCollapse = () => {
		setIsActive(!isActive)
	}
	return (
		<StyledCard>
			<BtnCollapse active={isActive} onClick={() => toggleCollapse()} />
			<DeliveryId>{deliveryId}</DeliveryId>
			<Status>{t(`deliveryStatus.${status}`)}</Status>
			<UpdatedTime>{updatedAt}</UpdatedTime>
			<StatusBarContainer statusBarStep={statusBarStep}>
				<StatusBar />
				<StatusBar />
				<StatusBar />
				<StatusBar />
			</StatusBarContainer>
			<LogInfo active={isActive} logs={logs} />
		</StyledCard>
	)
}

export default React.memo(DeliveryCard)
